<template>
  <div id="app" v-bind:class="currentTheme">
    <div class="context-title">
      <v-app-bar color="transparent" flat elevate-on-scroll>
        <v-btn
          class="context-button-color"
          icon
          bottom
          right
          offset-y
          @click="$router.push('/patients')"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <div v-if="!isMobile">
          <h3>{{ patientSelected.fullName }}</h3>
        </div>
        <div v-else>
          <h5>{{ patientSelected.fullName }}</h5>
        </div>
      </v-app-bar>
    </div>

    <v-row justify="center" class="context-card">
      <v-expansion-panels popout v-model="panel" focusable>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6" color="context-header"
            >Información del paciente</v-expansion-panel-header
          >
          <v-expansion-panel-content color="context-card-color">
            <PatientDetail
              :patientSelected="patientSelected"
              @closeEditPatient="closeWinEditPatient"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-2">
          <v-expansion-panel-header class="text-h6" color="context-header"
            >Notas médicas</v-expansion-panel-header
          >
          <v-expansion-panel-content color="context-card-color">
            <MedicalNote
              :patientSelected="patientSelected"
              @closeEditPatient="closeWinEditPatient"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-2">
          <v-expansion-panel-header class="text-h6" color="context-header"
            >Historial de consultas</v-expansion-panel-header
          >
          <v-expansion-panel-content color="context-card-color">
            <SchedulesByPatient :patientSelected="patientSelected" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-snackbar v-model="snackbar" bottom>
      <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import MedicalNote from "./MedicalNote.vue";
import helperServices from "../Services/helperServices";
import PatientDetail from "./PatientDetail.vue";
import SchedulesByPatient from "./SchedulesByPatient.vue";

export default {
  components: { MedicalNote, PatientDetail, SchedulesByPatient },
  data() {
    return {
      patientSelected: {},

      snackbar: false,
      messageSnackbar: "",
      tab: null,
      panel: 0,
      items: [
        {
          title: "Información",
          icon: "info",
        },
        { title: "Notas médicas", icon: "notes" },
      ],
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  methods: {
    closeWinEditPatient(result) {
      if (result.tipo == "cerrar") {
        this.AddPatientDialog = result.isVisible;
      } else if (result.tipo == "nuevo") {
        this.AddPatientDialog = result.isVisible;
        // this.snackbar = true;
        // this.messageSnackbar = "Paciente creado correctamente.";
        this.$fire({
          position: "top-end",
          title: "Paciente creado correctamente.",
          // text: "Introduzca la información correcta.",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        this.getPatients();
        // this.showSnackbar = true;
      } else if (result.tipo == "editar") {
        this.AddPatientDialog = result.isVisible;
        //this.snackbar = true;
        //this.messageSnackbar = "Paciente modificado correctamente.";
        this.$fire({
          position: "top-end",
          title: "Paciente modificado correctamente.",
          //text: "Introduzca la información correcta.",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        // this.showSnackbar = true;
      } else if (result.tipo == "editMedicalNote") {
        //this.snackbar = true;
        // this.messageSnackbar = "Nota modificada correctamente.";
        this.$fire({
          position: "top-end",
          title: "Nota modificada correctamente.",
          //text: "Introduzca la información correcta.",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      } else if (result.tipo == "insertMedicalNote") {
        // this.snackbar = true;
        // this.messageSnackbar = "Nota creada correctamente.";
        this.$fire({
          position: "top-end",
          title: "Nota creada correctamente.",
          //text: "Introduzca la información correcta.",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      } else if (result.tipo == "informationNote") {
        this.lastNote = result.lastNote;
        this.firstNote = result.firstNote;
        //this.calculateIMC();
        // this.lastNote.creationDate = moment(this.lastNote.creationDate).format(
        //   "DD/MM/YYYY hh:mm"
        // );
      } else if (result.tipo == "printMedicalNote") {
        //this.snackbar = true;
        //this.messageSnackbar = "No ha seleccionado ninguna nota para imprimir.";
        this.$fire({
          position: "top-end",
          title: "No ha seleccionado ninguna nota para imprimir.",
          //text: "Introduzca la información correcta.",
          type: "warning",
          //timer: 3000,
          showConfirmButton: true,
        }).then(() => {
          //console.log(r.value);
        });
      }
    },
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
    this.patientSelected = this.$route.params.patientSelected; //recupero el objeto del router

    //Source: https://www.holadevs.com/pregunta/74805/how-to-calculate-age-using-the-date-field-in-vuejs;
    //console.log(this.$route.params);
  },
  mounted() {},
};
</script>
<style></style>

<template>
  <div id="app" v-bind:class="currentTheme">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />

    <div class="context-title">
      <h1>Configuración</h1>
    </div>

    <div class="context-card">
      <div class="context-title">
        <h3>Tema de la aplicación</h3>
        <p>Selecciona el tema que será aplicado a la aplicación.</p>
      </div>

      <div class="theme-options">
        <div
          id="theme-white"
          v-bind="{ active: currentTheme === 'theme-white' }"
          v-on:click="swicthTheme('theme-white')"
        ></div>
        <div
          id="theme-blue"
          v-bind="{ active: currentTheme === 'theme-blue' }"
          v-on:click="swicthTheme('theme-blue')"
        ></div>
        <div
          id="theme-orange"
          v-bind="{ active: currentTheme === 'theme-orange' }"
          v-on:click="swicthTheme('theme-orange')"
        ></div>
        <div
          id="theme-purple"
          v-bind="{ active: currentTheme === 'theme-purple' }"
          v-on:click="swicthTheme('theme-purple')"
        ></div>
        <div
          id="theme-green"
          v-bind="{ active: currentTheme === 'theme-green' }"
          v-on:click="swicthTheme('theme-green')"
        ></div>
        <div
          id="theme-black"
          v-bind="{ active: currentTheme === 'theme-black' }"
          v-on:click="swicthTheme('theme-black')"
        ></div>
      </div>
      <!-- <div class="mb-3">
              <v-combobox
                v-model="select"
                :items="items"
                label="Selecciona el color del tema"
                item-text="id"
                item-value="value"
                @change="themeSelected"
              ></v-combobox>
            </div>-->
      <!--<div class="mb-1 mt-5">
        <v-card-actions>
          <v-btn class="button-style"> Aplicar tema </v-btn>
        </v-card-actions>
      </div>-->
    </div>
    <div class="context-card mt-5" v-show="false">
      <div class="context-title">
        <h3>Depurar base de datos</h3>
        <p>
          Elimina registros antiguos de la base de datos, selecciona los
          parámetros necesarios.
        </p>

        <div class="mb-3">
          <v-checkbox
            v-model="IsCleanEnabledBD"
            color="indigo"
            label="Eliminar datos antiguos"
            hide-details
          ></v-checkbox>
        </div>
        <div v-if="IsCleanEnabledBD">
          <v-flex xs11 md2>
            <v-text-field
              class="custom-placeholer-color"
              v-model="countYearToDelete"
              type="number"
              label="Años de respaldo"
              outlined
              dense
            ></v-text-field>
          </v-flex>
          <div>
            <h6>Se eliminaran todos los registros con fecha mayor</h6>
          </div>
          <v-card-actions>
            <v-btn class="context-button-style"> Eliminar registros </v-btn>
          </v-card-actions>
        </div>
        <v-divider class="mb-5"></v-divider>
        <h3>Exportar base de datos</h3>
        <p>Exporta la base de datos en formato Excel.</p>
        <v-file-input
          class="custom-placeholer-color"
          label="File input"
        ></v-file-input>
      </div>
      <div class="mb-1 mt-5">
        <v-card-actions>
          <v-btn class="context-button-style"> Exportar </v-btn>
        </v-card-actions>
      </div>
    </div>

    <v-snackbar v-model="snackbar" bottom>
      <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import LoadingProcess from "../components/LoadingProcess.vue";

export default {
  components: { LoadingProcess },
  data() {
    return {
      useruid: "",
      userSelected: [],
      id: -1,
      snackbar: false,
      messageSnackbar: "",
      isMobile: false,
      loadingShow: false,
      loadingTitle: "",
      countYearToDelete: 1,
      IsCleanEnabledBD: false,
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  computed: {
    //...mapGetters({
    // map `this.user` to `this.$store.getters.user`
    //  user: "user",
    // }),
    // formIsValid() {
    //   return this.form.specialty && this.form.phone;
    // },
  },

  async created() {
    this.loadingTitle = "Cargando la configuración...";
    this.isMobile = helperServices.MobileService.isMobile();
    //this.backgroundColorStyle.backgroundColor = "#ededed"; //este color es grey lighten-3
  },

  methods: {
    initialize() {},

    swicthTheme(theme) {
      this.userSelected = this.$store.getters.user.data;

      this.InsertUserSetting(theme);
    },

    InsertUserSetting(theme) {
      this.loadingShow = true;
      this.userSelected = this.$store.getters.user.data;

      let item = {
        id: 0,
        theme: theme,
        userUID: this.userSelected.userUID,
      };
      console.log(item);
      //Se inserta un nuevo registro
      apiServices.SettingService.InsertSetting(item)
        .then(() => {
          localStorage.setItem("theme-color", theme);
          this.currentTheme = localStorage.getItem("theme-color");
          //window.location.reload();
          this.loadingShow = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
  },
};
</script>
<style></style>

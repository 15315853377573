<template>
  <div id="app" v-bind:class="currentTheme">
    <v-toolbar flat class="context-toolbar">
      <v-toolbar-title class="context-title2" v-if="!isMobile">
        <h3>{{ titleEvent }}</h3>
      </v-toolbar-title>
      <v-toolbar-title class="context-title2" v-else>
        <h5>{{ titleEvent }}</h5>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon bottom right offset-y @click="closeWindow('cerrarEditEvent')">
        <v-icon class="context-icon-color">close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="context-card2">
      <v-form ref="form" @submit.prevent="submit">
        <div class="pa-4">
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-autocomplete
                v-model="form.patientSelected"
                :items="patients"
                label="Paciente"
                item-text="fullName"
                item-value="id"
                :rules="rules.patient"
                :readonly="!isNewEvent"
                required
                outlined
                dense
                placeholder="Escribe el paciente"
                class="custom-placeholer-color"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-text-field
                type="date"
                label="Fecha Cita"
                v-model="form.appointmentDate"
                outlined
                dense
                class="custom-placeholer-color"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around v-if="isMobile">
            <v-flex xs6 md6>
              <v-text-field
                type="time"
                label="Hora Inicio"
                v-model="form.horaInicio"
                outlined
                dense
                class="custom-placeholer-color"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 md6>
              <v-text-field
                type="time"
                label="Hora Fin"
                v-model="form.horaFin"
                outlined
                dense
                class="custom-placeholer-color"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around v-else>
            <v-flex xs6 md6>
              <v-text-field
                type="time"
                label="Hora Inicio"
                v-model="form.horaInicio"
                outlined
                dense
                class="mr-3"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 md6>
              <v-text-field
                type="time"
                label="Hora Fin"
                v-model="form.horaFin"
                outlined
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-select
                :items="['Consultorio', 'Domicilio', 'Video Consulta']"
                v-model="form.appointmentPlace"
                :rules="rules.appointmentPlace"
                label="Lugar"
                required
                outlined
                dense
              ></v-select>
            </v-flex>
          </v-layout>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formIsValid"
            type="submit"
            :loading="isLoading"
            small
            color="primary"
            rounded
          >
            <v-icon left> save </v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";

export default {
  data() {
    /* const defaultForm = Object.freeze({
      id: -1,
      patientSelected: null,
      appointmentDate: new Date().toISOString().substr(0, 10),
      horaInicio: null,
      horaFin: null,
      appointmentPlace: "",
    });*/

    return {
      //form: Object.assign({}, defaultForm),
      rules: {
        patient: [(val) => (val || "").length > 0 || ""],
        appointmentPlace: [
          (val) => (val || "").length > 0 || "Lugar es requerido.",
        ],
        // fechaCita: [(val) => (val || "").length > 0 || ""],
        // horaInicio: [(val) => (val || "").length > 0 || ""],
        // horaFin: [(val) => (val || "").length > 0 || ""],
      },
      // defaultForm,
      patients: [],
      isLoading: false,
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  props: {
    form: null,
    titleEvent: null,
    isNewEvent: null,
  },
  computed: {
    formIsValid() {
      return (
        this.form.patientSelected &&
        this.form.appointmentDate &&
        this.form.horaInicio &&
        this.form.horaFin &&
        this.form.appointmentPlace
      );
    },
  },
  mounted() {},

  methods: {
    getPatients() {
      apiServices.patientService
        .GetPatients()
        .then((resp) => {
          this.patients = resp.data;
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    resetForm() {
      // this.form = Object.assign({}, this.defaultForm);
      // this.$refs.form.reset();
    },
    enforcePhoneFormat() {
      let x = this.infoSelected.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.infoSelected.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async submit() {
      this.isLoading = true;
      let item = {
        id: this.form.id,
        patientId: null,
        appointmentDate: this.form.appointmentDate,
        start: this.form.horaInicio,
        end: this.form.horaFin,
        scheduleState: "Programada", //Programada,Confirmada,Cancelada
        statusColor: "grey darken-1",
        appointmentPlace: this.form.appointmentPlace,
      };

      if (this.form.id == 0) {
        item.patientId = this.form.patientSelected;
        //Se inserta un nuevo registro
        apiServices.scheduleService
          .InsertSchedule(item)
          .then(() => {
            //this.resetForm();
            this.closeWindow("insertEvent");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      } else {
        item.patientId = this.form.patientSelected.id;
        //edita un evento
        await apiServices.scheduleService
          .UpdateSchedule(item)
          .then(() => {
            this.closeWindow("editEventSave");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
    },
    closeWindow(value) {
      if (value == "editEventSave") {
        let result = {
          isVisible: false,
          tipo: "editEventSave",
        };
        this.$emit("closeEditEvent", result);
      } else if (value == "insertEvent") {
        let result = {
          isVisible: false,
          tipo: "insertEvent",
        };
        this.$emit("closeEditEvent", result);
      } else if (value == "cerrarEditEvent") {
        let result = {
          isVisible: false,
          tipo: "cerrarEditEvent",
        };
        this.$emit("closeEditEvent", result);
      }
      //  this.resetForm();
    },
  },
  created() {
    this.getPatients();
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>

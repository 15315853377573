<template>
  <div id="app" v-bind:class="currentTheme">
    <div class="context-card2">
      <!--Con la etiqueta v-sheet simulo la parte pintada de alert en card-->
      <v-sheet
        outlined
        :color="selectedEvent.color"
        rounded="0"
        max-height="13"
      >
        <br />
      </v-sheet>
      <v-toolbar flat class="context-toolbar" color="transparent">
        <v-toolbar-title>
          <div class="context-title2">
            <h5>{{ selectedEvent.name }}</h5>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon right offset-y @click="closeWindow('cerrar')">
          <v-icon class="context-icon-color">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="selectedEvent">
        <div>
          <v-container>
            <v-layout row wrap justify-space-around>
              <v-flex xs11 md12>
                <div class="d-flex pa-2" flat>
                  <v-container>
                    <v-row justify="center">
                      <v-chip
                        class="d-flex pa-2 rounded-lg context-button-style"
                        label
                        large
                        outlined
                      >
                        <span>
                          <v-icon>today</v-icon>
                          Fecha: {{ selectedEvent.appointmentDate }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="d-flex pa-2 rounded-lg context-button-style"
                        label
                        outlined
                        large
                      >
                        <span>
                          <v-icon>schedule</v-icon>
                          Hora: {{ selectedEvent.horario }}
                        </span>
                      </v-chip>
                    </v-row>
                    <!--<v-row justify="center" v-else>
                      <v-chip
                        class="d-flex pa-2 rounded-lg button-style"
                        label
                        outlined
                        large
                      >
                        <span>
                          <v-icon>today</v-icon>
                          Fecha: {{ selectedEvent.appointmentDate }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="d-flex pa-2 rounded-lg button-style"
                        label
                        outlined
                        large
                      >
                        <span>
                          <v-icon>schedule</v-icon>
                          Hora: {{ selectedEvent.horario }}
                        </span>
                      </v-chip>
                    </v-row>-->
                  </v-container>
                </div>
              </v-flex>
              <v-flex xs11 md8>
                <div class="d-flex pa-2 mt-2" flat>
                  <v-row justify="center">
                    <v-alert
                      :color="selectedEvent.color"
                      elevation="4"
                      prominent
                      dense
                      dark
                      class="mx-auto rounded-xl"
                    >
                      <span v-if="selectedEvent.scheduleState === 'Programada'">
                        Cita programada
                      </span>
                      <span v-if="selectedEvent.scheduleState === 'Confirmada'">
                        Cita confirmada</span
                      >
                      <span v-if="selectedEvent.scheduleState === 'Cancelada'">
                        Cita cancelada</span
                      >
                    </v-alert>
                  </v-row>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <div class="context-card2">
            <v-flex xs11 md12>
              <div class="d-flex pa-2" flat>
                <v-chip
                  v-if="selectedEvent.typeAppointment"
                  color="orange"
                  dark
                  text-color="white"
                  label
                  small
                >
                  <span>
                    <v-icon>verified</v-icon> Paciente tipo "{{
                      selectedEvent.typeAppointment
                    }}"
                  </span>

                  <!-- <v-icon right v-if="selectedEvent.categoria === 'Premium'">
                      star
                    </v-icon>-->
                </v-chip>
                <v-chip
                  v-else
                  color="#F1C40F"
                  text-color="white"
                  label
                  small
                  dark
                >
                  <span>
                    <v-icon>warning</v-icon> No se ha definido el tipo de
                    paciente
                  </span>

                  <!-- <v-icon right v-if="selectedEvent.categoria === 'Premium'">
                      star
                    </v-icon>-->
                </v-chip>
              </div>
            </v-flex>

            <v-layout row wrap justify-space-around class="mt-5 mx-4">
              <v-flex
                xs11
                md12
                v-if="selectedEvent.typeAppointment === 'Pemex'"
              >
                <span>
                  <p>
                    <v-icon class="context-icon-color">tag</v-icon> No Ficha:
                    {{ selectedEvent.patient.numberSheet }}
                  </p>
                </span>
              </v-flex>
              <v-flex xs11 md12>
                <span>
                  <p>
                    <v-icon class="context-icon-color">face</v-icon> Paciente
                    {{ selectedEvent.patientState }}
                  </p>
                </span>
              </v-flex>

              <v-flex xs11 md12>
                <div v-if="selectedEvent.appointmentPlace === 'Domicilio'">
                  <p>
                    <v-icon class="context-icon-color">house</v-icon> Consulta
                    en
                    {{ selectedEvent.appointmentPlace }}
                  </p>
                </div>
                <div
                  v-else-if="selectedEvent.appointmentPlace === 'Consultorio'"
                >
                  <p>
                    <v-icon class="context-icon-color">local_hospital</v-icon>
                    Consulta en
                    {{ selectedEvent.appointmentPlace }}
                  </p>
                </div>
                <div
                  v-else-if="
                    selectedEvent.appointmentPlace === 'Video Consulta'
                  "
                >
                  <span
                    ><p>
                      <v-icon class="context-icon-color">video_call</v-icon>
                      {{ selectedEvent.appointmentPlace }}
                    </p></span
                  >
                </div>
              </v-flex>
              <v-flex xs11 md12>
                <div v-if="selectedEvent.phone">
                  <span
                    ><p>
                      <v-icon class="context-icon-color">phone</v-icon>
                      {{ selectedEvent.phone }}
                    </p></span
                  >
                </div>
              </v-flex>
              <v-flex xs11 md6>
                <div>
                  <v-btn
                    rounded
                    color="secondary"
                    small
                    right
                    @click="showHistorySchedule()"
                    ><v-icon left> history </v-icon>Historial de
                    consultas</v-btn
                  >
                </div>
              </v-flex>
            </v-layout>

            <!--<v-flex xs11 md12>
              <div v-if="selectedEvent.email" class="d-flex pa-2" flat>
                <span><v-icon>email</v-icon> {{ selectedEvent.email }}</span>
              </div>
            </v-flex>
            <v-flex xs11 md12>
              <div v-if="selectedEvent.address" class="d-flex pa-2" flat>
                <span
                  ><v-icon>location_on</v-icon>
                  {{ selectedEvent.address }}</span
                >
              </div>
            </v-flex>-->
          </div>
        </div>

        <v-card-actions v-show="selectedEvent.scheduleState == 'Programada'">
          <v-spacer></v-spacer>
          <template v-if="!isMobile">
            <v-btn
              class="white--text text-caption"
              @click="confirmEvent()"
              color="green darken-1"
              rounded
              small
              dark
              width="115"
            >
              <v-icon left> check </v-icon>
              Confirmar
            </v-btn>

            <v-btn
              class="white--text text-caption"
              dark
              @click="editEvent()"
              color="blue darken-1"
              rounded
              small
              width="80"
            >
              <v-icon left> edit </v-icon>
              Editar
            </v-btn>

            <v-btn
              class="white--text text-caption"
              dark
              @click="cancelEvent()"
              color="red darken-4"
              rounded
              small
              width="110"
            >
              <v-icon left> event_busy </v-icon>
              Cancelar
            </v-btn>

            <v-btn
              class="white--text text-caption"
              dark
              @click="deleteEvent()"
              color="red darken-1"
              rounded
              small
              width="100"
            >
              <v-icon left> delete </v-icon>
              Eliminar
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="ma-2 white--text text-caption"
              @click="confirmEvent()"
              color="green darken-1"
              rounded
              small
            >
              <v-icon left> check </v-icon>
              <!-- 
             //esto es para alinear dos componentes de manera vertical
             <div class="wrapper">
              <div>
                <v-icon left> check </v-icon>
              </div>
              <div class="text-6">Confirmar</div>
            </div>-->
            </v-btn>

            <v-btn
              class="ma-2 white--text text-caption"
              @click="editEvent()"
              color="blue darken-1"
              rounded
              small
            >
              <v-icon left> edit </v-icon>
            </v-btn>

            <v-btn
              class="ma-2 white--text text-caption"
              @click="cancelEvent()"
              color="red darken-4"
              rounded
              small
            >
              <v-icon left> event_busy </v-icon>
            </v-btn>

            <v-btn
              class="ma-2 white--text text-caption"
              @click="deleteEvent()"
              color="red darken-1"
              rounded
              small
            >
              <v-icon left> delete </v-icon>
            </v-btn>
          </template>
        </v-card-actions>
        <v-card-actions v-show="selectedEvent.scheduleState == 'Confirmada'">
          <v-spacer></v-spacer>
          <template v-if="!isMobile">
            <v-btn
              class="ma-2 white--text text-caption"
              @click="editEvent()"
              color="blue darken-1"
              rounded
              small
            >
              <v-icon left> edit </v-icon>
              Editar
            </v-btn>

            <v-btn
              class="ma-2 white--text text-caption"
              @click="cancelEvent()"
              color="red darken-4"
              rounded
              small
            >
              <v-icon left> event_busy </v-icon>
              Cancelar
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="ma-2 white--text text-caption"
              @click="editEvent()"
              color="blue darken-1"
              rounded
              small
            >
              <v-icon left> edit </v-icon>
            </v-btn>

            <v-btn
              class="ma-2 white--text text-caption"
              @click="cancelEvent()"
              color="red darken-4"
              rounded
              small
            >
              <v-icon left> event_busy </v-icon>
            </v-btn>
          </template>
        </v-card-actions>
        <v-card-actions v-show="selectedEvent.scheduleState == 'Cancelada'">
          <v-spacer></v-spacer>
          <template v-if="!isMobile">
            <v-btn
              class="ma-2 white--text text-caption"
              @click="scheduleEvent(selectedEvent)"
              color="grey darken-1"
              rounded
              small
            >
              <v-icon left> schedule </v-icon>
              Programar de nuevo
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="ma-2 white--text text-caption"
              @click="scheduleEvent(selectedEvent)"
              color="grey darken-1"
              rounded
              small
            >
              <v-icon left> schedule </v-icon>
            </v-btn>
          </template>
        </v-card-actions>
      </v-form>
      <v-dialog
        v-model="showHistory"
        :close-on-content-click="false"
        offset-x
        max-width="600px"
        persistent
        transition="dialog-bottom-transition"
      >
        <ShowPatientHistorySchedule
          :selectedEvent="selectedEvent"
          @closeScheduleDetail="closeWindow"
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import ShowPatientHistorySchedule from "./ShowPatientHistorySchedule.vue";

export default {
  components: { ShowPatientHistorySchedule },
  data() {
    return {
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
      showHistory: false,
    };
  },
  props: {
    selectedEvent: null,
  },
  computed: {},
  mounted() {},

  methods: {
    async confirmEvent() {
      //confirma una consulta
      let item = {
        id: this.selectedEvent.id,
        patientId: this.selectedEvent.patientId,
        appointmentDate: this.selectedEvent.appointmentDate,
        start: this.selectedEvent.horaInicio,
        end: this.selectedEvent.horaFin,
        scheduleState: "Confirmada", //Programada,Confirmada,Cancelada
        statusColor: "green darken-1",
        appointmentPlace: this.selectedEvent.appointmentPlace,
      };
      //selectedEvent.scheduleState = "Confirmada";
      //selectedEvent.color = "green darken-1";
      //this.selectedEvent = selectedEvent;

      await apiServices.scheduleService
        .UpdateSchedule(item)
        .then(() => {
          this.closeWindow("confirmEvent");
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    editEvent() {
      this.closeWindow("editEvent");
    },
    async cancelEvent() {
      //cancela una consulta
      let item = {
        id: this.selectedEvent.id,
        patientId: this.selectedEvent.patientId,
        appointmentDate: this.selectedEvent.appointmentDate,
        start: this.selectedEvent.horaInicio,
        end: this.selectedEvent.horaFin,
        scheduleState: "Cancelada", //Programada,Confirmada,Cancelada
        statusColor: "red darken-4",
        appointmentPlace: this.selectedEvent.appointmentPlace,
      };
      //selectedEvent.scheduleState = "Cancelada";
      //selectedEvent.color = "red darken-4";
      //this.selectedEvent = selectedEvent;

      await apiServices.scheduleService
        .UpdateSchedule(item)
        .then(() => {
          this.closeWindow("cancelEvent");
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    deleteEvent() {
      //elimina una consulta
      this.$fire({
        title: "Desea eliminar la consulta?",
        text: this.selectedEvent.horario + " " + this.selectedEvent.name,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          apiServices.scheduleService
            .DeleteSchedule(this.selectedEvent.id)
            .then(() => {
              this.closeWindow("deleteEvent");
            })
            .catch((error) => {
              if (error.response) {
                // get response with a status code not in range 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // no response
                console.log(error.request);
                // instance of XMLHttpRequest in the browser
                // instance ofhttp.ClientRequest in node.js
              } else {
                // Something wrong in setting up the request
                console.log("Error", error.message);
              }
              console.log("Error.config: " + error.config);
            });
        }
      });
    },
    async scheduleEvent() {
      //programa de nuevo una consulta cancelada

      let item = {
        id: this.selectedEvent.id,
        patientId: this.selectedEvent.patientId,
        appointmentDate: this.selectedEvent.appointmentDate,
        start: this.selectedEvent.horaInicio,
        end: this.selectedEvent.horaFin,
        scheduleState: "Programada", //Programada,Confirmada,Cancelada
        statusColor: "grey darken-1",
        appointmentPlace: this.selectedEvent.appointmentPlace,
      };
      //selectedEvent.scheduleState = "Programada";
      //selectedEvent.color = "grey darken-1";
      //this.selectedEvent = selectedEvent;

      await apiServices.scheduleService
        .UpdateSchedule(item)
        .then(() => {
          this.closeWindow("scheduleEvent");
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    showHistorySchedule() {
      this.showHistory = true;
    },
    closeWindow(value) {
      if (value == "confirmEvent") {
        let result = {
          isVisible: false,
          tipo: "confirmEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "editEvent") {
        let result = {
          isVisible: false,
          tipo: "editEvent",
          item: this.selectedEvent,
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "cancelEvent") {
        let result = {
          isVisible: false,
          tipo: "cancelEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "deleteEvent") {
        let result = {
          isVisible: false,
          tipo: "deleteEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "scheduleEvent") {
        let result = {
          isVisible: false,
          tipo: "scheduleEvent",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEventDetail", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEventDetail", result);
      } else if (value === "closeScheduleDetail") {
        this.showHistory = false;
      }

      //  this.resetForm();
    },
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<template>
  <div id="app" v-bind:class="currentTheme">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <div class="context-title">
      <h1>Calendario</h1>
    </div>
    <div class="context-card">
      <v-row>
        <v-spacer></v-spacer>
        <template v-if="!isMobile">
          <v-btn
            rounded
            dark
            @click="refreshEvents()"
            color="orange"
            class="mr-5"
            small
          >
            <v-icon left>refresh</v-icon>
            Cargar de nuevo
          </v-btn>
          <v-btn
            rounded
            dark
            @click="addNewEvent()"
            color="primary"
            class="mr-5"
            small
          >
            <v-icon left>event</v-icon>
            Nueva Consulta
          </v-btn>

          <v-btn
            rounded
            dark
            @click="openPrintDialog()"
            color="black"
            class="mr-5"
            small
          >
            <v-icon left>print</v-icon>
            Imprimir
          </v-btn>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="refreshEvents()"
                color="orange"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <span>Cargar de nuevo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="addNewEvent()"
                color="primary"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>event</v-icon>
              </v-btn>
            </template>
            <span>Nueva Consulta</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="openPrintDialog()"
                color="black"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>print</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
        </template>
      </v-row>

      <div v-if="isMobile">
        <v-toolbar dense flat light class="context-toolbar">
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <h3 v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </div>

      <v-toolbar flat dense v-if="!isMobile" class="context-toolbar mt-3">
        <v-toolbar-title>
          <v-btn
            outlined
            small
            right
            class="mr-4 context-icon-color"
            @click="setToday"
          >
            Hoy
          </v-btn>

          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                small
                class="context-icon-color"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> expand_more </v-icon>
              </v-btn>
            </template>
            <v-list class="context-card2">
              <v-list-item @click="type = 'day'">
                <v-list-item-title class="context-button-text-color"
                  >Día</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title class="context-button-text-color"
                  >Semana</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title class="context-button-text-color"
                  >Mes</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title class="context-button-text-color"
                  >4 días</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = 'category'">
                <v-list-item-title class="context-button-text-color"
                  >Categoria</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <div v-if="$refs.calendar" class="context-title">
          <h3>{{ $refs.calendar.title }}</h3>
        </div>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn
          fab
          outlined
          small
          left
          class="mr-5 context-icon-color"
          @click="prev"
        >
          <v-icon> chevron_left </v-icon>
        </v-btn>

        <v-btn
          fab
          outlined
          small
          right
          class="context-icon-color"
          @click="next"
        >
          <v-icon> chevron_right </v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar flat dense v-else class="context-toolbar">
        <v-toolbar-title>
          <v-btn
            outlined
            small
            right
            class="mr-1 context-icon-color"
            @click="setToday"
          >
            Hoy
          </v-btn>

          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                small
                class="context-icon-color"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> expand_more </v-icon>
              </v-btn>
            </template>
            <v-list class="context-card2">
              <v-list-item @click="type = 'day'">
                <v-list-item-title class="context-button-text-color"
                  >Día</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title class="context-button-text-color"
                  >Semana</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title class="context-button-text-color"
                  >Mes</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title class="context-button-text-color"
                  >4 días</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="type = 'category'">
                <v-list-item-title class="context-button-text-color"
                  >Categoria</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          fab
          outlined
          small
          left
          class="mr-1 context-icon-color"
          @click="prev"
        >
          <v-icon> chevron_left </v-icon>
        </v-btn>

        <v-btn
          fab
          outlined
          small
          right
          class="context-icon-color"
          @click="next"
        >
          <v-icon> chevron_right </v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        color="transparent"
      >
        <v-calendar
          ref="calendar"
          :dark="isDark"
          class="context-calendar mt-3"
          locale="es-MX"
          v-model="focus"
          color="currentdaycolor"
          is-range
          first-interval="6"
          interval-minutes="60"
          interval-count="18"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          category-show-all
          :categories="categories"
          :event-height="20"
          :event-margin-bottom="5"
          :short-intervals="false"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          @click:time="addEvent"
        >
        </v-calendar>
      </v-sheet>

      <!--Componente para imprimir-->
      <v-dialog
        v-model="showPrintDialog"
        persistent
        max-width="600px"
        transition="dialog-bottom-transition"
      >
        <PrintEvents :events="events" @closeEventDetail="closeWinEventDetail" />
      </v-dialog>
      <!--Componente para el detalle del evento-->
      <v-dialog
        v-model="openEventDetailDialog"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        max-width="500px"
        persistent
        transition="dialog-bottom-transition"
        scrollable
      >
        <ShowEventDetail
          :selectedEvent="selectedEvent"
          @closeEventDetail="closeWinEventDetail"
        />
      </v-dialog>
      <!--Componente para crear o editar un evento-->
      <v-dialog
        v-model="addEventDialog"
        max-width="500px"
        persistent
        transition="dialog-bottom-transition"
      >
        <EditEvent
          :form="form"
          :titleEvent="titleEvent"
          :isNewEvent="isNewEvent"
          @closeEditEvent="closeWinEditEvent"
        />
      </v-dialog>

      <v-snackbar v-model="snackbar" bottom>
        <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
        {{ messageSnackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import LoadingProcess from "../components/LoadingProcess.vue";
import ShowEventDetail from "../components/ShowEventDetail.vue";
import EditEvent from "../components/EditEvent.vue";
import PrintEvents from "../components/PrintEvents.vue";
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";

export default {
  components: { ShowEventDetail, EditEvent, LoadingProcess, PrintEvents },
  data: () => {
    return {
      form: {},
      rules: {
        patient: [(val) => (val || "").length > 0 || ""],
        appointmentPlace: [
          (val) => (val || "").length > 0 || "Lugar es requerido.",
        ],
        // fechaCita: [(val) => (val || "").length > 0 || ""],
        // horaInicio: [(val) => (val || "").length > 0 || ""],
        // horaFin: [(val) => (val || "").length > 0 || ""],
      },
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        "4day": "4 Días",
        category: "Categoria",
      },
      categories: ["Primera Vez", "Subsecuente"],
      selectedEvent: {},
      titleEvent: "",
      isNewEvent: false,
      openEventDetailDialog: false,
      addEventDialog: false,
      selectedElement: null,
      events: [],
      names: ["Programada", "Confirmada", "Cancelada", "Vencida"],
      viewDaySelected: "",
      showPrintDialog: false,
      selectDateReport: new Date().toISOString().substr(0, 10),
      snackbar: false,
      messageSnackbar: "",
      loadingShow: false,
      loadingTitle: "",
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
      isDark: false,
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  async created() {
    this.isMobile = helperServices.MobileService.isMobile();
    if (this.currentTheme == "theme-black") this.isDark = true;
    else this.isDark = false;
  },
  computed: {},

  methods: {
    refreshEvents() {
      this.updateRange();
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
      this.viewDaySelected = date;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.openEventDetailDialog = true))
        );
      };

      if (this.openEventDetailDialog) {
        this.openEventDetailDialog = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
      //this.selectedEvent = event;
      //this.openEventDetailDialog = true;
    },
    async updateRange() {
      const events = [];
      var schedulesDBList = [];
      this.loadingTitle = "Cargando el calendario...";
      this.loadingShow = true;
      // console.log(this.$refs.calendar.$data.lastEnd)
      var filter = {
        userUID: "",
        month: this.$refs.calendar.$data.lastEnd.month,
        year: this.$refs.calendar.$data.lastEnd.year,
      };
      apiServices.scheduleService
        .GetSchedulesFilter(filter)
        .then((resp) => {
          schedulesDBList = resp.data;

          schedulesDBList.forEach((ev) => {
            //todas las citas confirmadas pasadas a la fecha actual se vuelven Ejecutadas
            /* let colorCitaEjecutada = "";
            if (new Date(ev.appointmentDate + "T" + ev.end) < new Date()) {
              if (ev.scheduleState === "Confirmada") {
                colorCitaEjecutada = "orange darken-1";
              } else {
                colorCitaEjecutada = ev.statusColor;
              }
            } else {
              colorCitaEjecutada = ev.statusColor;

            }*/

            events.push({
              // idEvent: ev.id,
              patientId: ev.patientId,
              id: ev.id,
              name: ev.patient.fullName,
              start: new Date(ev.appointmentDate + "T" + ev.start),
              end: new Date(ev.appointmentDate + "T" + ev.end),
              color: ev.statusColor,
              timed: true,
              horario: ev.start + " - " + ev.end,
              address: ev.patient.address,
              phone: ev.patient.phone,
              typeAppointment: ev.patient.typeAppointment,
              patientState: ev.patient.patientState,
              appointmentDate: ev.appointmentDate,
              email: ev.patient.email,
              scheduleState: ev.scheduleState,
              horaInicio: ev.start,
              horaFin: ev.end,
              category: ev.patient.patientState,
              appointmentPlace: ev.appointmentPlace,
              patient: ev.patient,
            });
          });

          this.events = events;
          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          //console.log("Error.config: " + error.config);
        });
    },

    openPrintDialog() {
      this.showPrintDialog = true;
    },

    addNewEvent() {
      //marco que es nueva cita
      let item = {
        id: 0,
        patientSelected: {},
        appointmentDate: null,
        horaInicio: null,
        horaFin: null,
        appointmentPlace: null,
      };
      //asigno la fecha actual
      const current = new Date();
      var mes = current.getMonth() + 1;
      mes = mes < 10 ? "0" + mes : mes;
      var dia = current.getDate();
      dia = dia < 10 ? "0" + dia : dia;
      const date = `${current.getFullYear()}-${mes}-${dia}`;

      item.appointmentDate = date; //new Date().toISOString().substr(0, 10);
      this.titleEvent = "Agregar nueva consulta";
      this.form = item;
      this.isNewEvent = true;
      this.addEventDialog = true;
    },

    addEvent({ date, time }) {
      //marco que es nueva cita
      let item = {
        id: 0,
        patientSelected: {},
        appointmentDate: null,
        horaInicio: null,
        horaFin: null,
        appointmentPlace: null,
      };

      //asigno la fecha seleccionada en el calendario
      item.appointmentDate = new Date(date).toISOString().substr(0, 10);
      //asigno la hora de inicio donde se dio clic en el calendario
      item.horaInicio = time;
      //a partir de la horainicio sumo siempre 30 minutos a la horafin
      var dt = new Date(date + "T" + time);
      dt.setMinutes(dt.getMinutes() + 30);

      var itemhorafin = dt.getHours();
      if (itemhorafin <= 9) itemhorafin = "0" + itemhorafin;
      var itemminutosfin = dt.getMinutes();
      if (itemminutosfin <= 9) itemminutosfin = "0" + itemminutosfin;
      item.horaFin = itemhorafin + ":" + itemminutosfin;

      this.titleEvent = "Agregar nueva consulta";
      this.form = item;
      this.isNewEvent = true;
      this.addEventDialog = true;
    },

    editEvent(selectedEvent) {
      //obtengo  el objeto paciente
      let patient = selectedEvent.patient;

      let item = {
        id: selectedEvent.id,
        patientSelected: patient,
        appointmentDate: new Date(selectedEvent.appointmentDate)
          .toISOString()
          .substr(0, 10),
        horaInicio: selectedEvent.horaInicio,
        horaFin: selectedEvent.horaFin,
        appointmentPlace: selectedEvent.appointmentPlace,
      };

      this.titleEvent = "Editar consulta";
      this.form = item;
      this.isNewEvent = false;
      this.addEventDialog = true;
    },

    closeWinEventDetail(result) {
      //regreso del dialog de EventDetail
      if (result.tipo === "cerrar") {
        this.openEventDetailDialog = result.isVisible;
      } else if (result.tipo === "confirmEvent") {
        this.openEventDetailDialog = result.isVisible;
        this.updateRange();
        //this.snackbar = true;
        //this.messageSnackbar = "Consulta confirmada.";
        this.$fire({
          position: "top-end",
          title: "Consulta confirmada correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "info",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      } else if (result.tipo === "editEvent") {
        this.openEventDetailDialog = result.isVisible;
        this.editEvent(result.item);
      } else if (result.tipo === "cancelEvent") {
        this.openEventDetailDialog = result.isVisible;
        this.updateRange();
        //this.snackbar = true;
        //this.messageSnackbar = "Consulta cancelada.";
        this.$fire({
          position: "top-end",
          title: "Consulta cancelada correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "info",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      } else if (result.tipo === "deleteEvent") {
        this.openEventDetailDialog = result.isVisible;
        this.updateRange();
        //this.snackbar = true;
        //this.messageSnackbar = "Consulta eliminada.";
        this.$fire({
          position: "top-end",
          title: "Consulta eliminada correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "info",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      } else if (result.tipo === "scheduleEvent") {
        this.openEventDetailDialog = result.isVisible;
        this.updateRange();
        //this.snackbar = true;
        // this.messageSnackbar = "Consulta programada.";
        this.$fire({
          position: "top-end",
          title: "Consulta programada correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "info",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      }
      //regreso del dialog de PrintEvent
      else if (result.tipo === "cerrarPrintEvents") {
        this.showPrintDialog = result.isVisible;
      }
    },
    closeWinEditEvent(result) {
      if (result.tipo === "cerrarEditEvent") {
        this.addEventDialog = result.isVisible;
      } else if (result.tipo === "insertEvent") {
        this.addEventDialog = result.isVisible;
        this.updateRange();
        //this.snackbar = true;
        //this.messageSnackbar = "Consulta agregada.";
        this.$fire({
          position: "top-end",
          title: "Consulta agregada correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      } else if (result.tipo === "editEventSave") {
        this.addEventDialog = result.isVisible;
        this.updateRange();
        //this.snackbar = true;
        //this.messageSnackbar = "Consulta modificada.";
        this.$fire({
          position: "top-end",
          title: "Consulta modificada correctamente!",
          // text: "El registro fue eliminado de la base de datos",
          type: "success",
          timer: 3000,
          toast: true,
          //background: "green",
          //color: "white",
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      }
    },
  },
};
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>

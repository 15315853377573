<template>
  <div id="app" v-bind:class="currentTheme">
    <v-toolbar flat class="context-toolbar">
      <v-toolbar-title class="context-title2">
        <h3>Modificar información</h3>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon bottom right offset-y @click="closeWindow('cerrar')">
        <v-icon class="context-icon-color">close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="context-card2">
      <v-form ref="infoSelected" @submit.prevent="submit" class="mx-4">
        <v-card-text>
          <v-container>
            <v-layout row wrap justify-space-around>
              <v-flex xs11 md12>
                <v-text-field
                  v-model="infoSelected.displayName"
                  :rules="rules.displayName"
                  label="Nombre Completo"
                  required
                  outlined
                  dense
                  placeholder="Introduce el nombre completo"
                  class="custom-placeholer-color"
                  append-icon="person"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
              <v-flex xs11 md12>
                <v-text-field
                  v-model="infoSelected.phone"
                  :rules="rules.phone"
                  class="custom-placeholer-color"
                  label="Teléfono"
                  required
                  outlined
                  dense
                  append-icon="phone"
                  @input="enforcePhoneFormat()"
                  maxlength="16"
                  placeholder="(123) 456-7890"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
              <v-flex xs11 md12>
                <v-text-field
                  v-model="infoSelected.specialty"
                  :rules="rules.specialty"
                  label="Especialidad"
                  required
                  outlined
                  dense
                  placeholder="Introduce la especialidad"
                  class="custom-placeholer-color"
                  append-icon="contact_page"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <!-- <v-divider class="mt-12"></v-divider> -->
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="!formIsValid"
            :loading="isLoading"
            class="context-button-style"
            type="submit"
            small
          >
            <v-icon left> save </v-icon>
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";

export default {
  data() {
    const defaultForm = Object.freeze({
      displayName: "",
      password: "",
      email: "",
      phone: "",
      specialty: "",
    });

    return {
      form: Object.assign({}, defaultForm),
      isLoading: false,
      rules: {
        displayName: [(val) => (val || "").length > 0 || "Nombre requerido."],
        phone: [(val) => (val || "").length > 0 || "Teléfono requerido."],
        specialty: [
          (val) => (val || "").length > 0 || "Especialidad requerida.",
        ],
      },
      defaultForm,
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  props: {
    infoSelected: null,
  },
  computed: {
    formIsValid() {
      return (
        this.infoSelected.displayName &&
        this.infoSelected.phone &&
        this.infoSelected.specialty
      );
    },
  },
  mounted() {
    //  this.isVisible = this.props.patientSelected.isVisible;
    // console.log(this.props.patientSelected);
    // this.patientSelected = this.props.patientSelected;
  },

  methods: {
    resetForm() {
      this.infoSelected = Object.assign({}, this.defaultForm);
      this.$refs.infoSelected.reset();
    },
    enforcePhoneFormat() {
      let x = this.infoSelected.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.infoSelected.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    submit() {
      this.isLoading = true;
      let item = {
        //id: this.infoSelected.id,
        userName: this.infoSelected.displayName,
        password: "",
        email: this.infoSelected.email,
        phone: this.infoSelected.phone,
        specialty: this.infoSelected.specialty,
        userUID: "",
        role: "",
      };

      apiServices.userService
        .UpdateUser(item)
        .then(() => {
          // var userSelected = this.$store.getters.user.data;
          /*  let user = {
            displayName: item.userName,
            email: item.email,
            uid: userSelected.uid,
            role: userSelected.role,
            phone: item.phone,
            specialty: item.specialty,
          };
          this.$store.dispatch("fetchUser", user);*/
          this.closeWindow("editar");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    closeWindow(value) {
      if (value == "nuevo") {
        let result = {
          isVisible: false,
          tipo: "nuevo",
        };
        this.$emit("closeEditInformation", result);
      } else if (value == "editar") {
        let result = {
          isVisible: false,
          tipo: "editar",
        };
        this.$emit("closeEditInformation", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEditInformation", result);
      }
      //  this.resetForm();
    },
  },
  created() {},
};
</script>

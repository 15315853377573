<template>
  <div id="app" v-bind:class="currentTheme">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <div class="context-title">
      <h1>Indicadores</h1>
    </div>
    <div>
      <v-row justify="space-between" align="center">
        <v-col lg="12" cols="12">
          <DashboardMonth :infoSelected="dashboardMonth" />
        </v-col>
        <v-col cols="12" md="12">
          <DashboardDay
            :infoSelected="dashboardDay"
            :listDashboardDay="listDashboardDay"
          />
        </v-col>
        <!--<v-col cols="12" md="6">
            <BarChart :chartData="chartData" />
          </v-col>
          <v-col cols="12" md="6">
            <LineChart />
          </v-col>-->
        <v-col cols="12" md="6">
          <PieChart
            :chartData="chartDataPieChart"
            :title="DashboardPieTitle"
            :subtitle="DashboardPieSubtitle"
          />
        </v-col>
        <v-col cols="12" md="6">
          <DoughnutChart
            :chartData="chartDataDoughnutChart"
            :title="DashboardDoughTitle"
            :subtitle="DashboardDoughSubtitle"
          />
        </v-col>
        <!--<v-col cols="12" md="6">
            <DashboardYear
              class="chart mt-15 mr-10"
              :infoSelected="optionDashboardScheduleStateYear"
            />
          </v-col>
          <v-col cols="12" md="6">
            <DashboardYear
              class="chart mt-15 mr-10"
              :infoSelected="optionDashboardAppointmentPlaceYear"
            />
          </v-col>-->
        <v-col cols="12" md="12">
          <DashboardPatientType :infoSelected="dashboarPatientType" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";
import DashboardMonth from "../components/DashboardMonth.vue";
//import DashboardYear from "../components/DashboardYear.vue";
import DashboardDay from "../components/DashboardDay.vue";
import LoadingProcess from "../components/LoadingProcess.vue";
import helperServices from "../Services/helperServices";
//import BarChart from "../components/Dashboards/BarChart.vue";
import DoughnutChart from "../components/Dashboards/DoughnutChart.vue";
//import LineChart from "../components/Dashboards/LineChart.vue";
import PieChart from "../components/Dashboards/PieChart.vue";
import DashboardPatientType from "../components/Dashboards/DashboardPatientType.vue";

export default {
  components: {
    DashboardMonth,
    //DashboardYear,
    DashboardDay,
    LoadingProcess,
    // BarChart,
    DoughnutChart,
    // LineChart,
    PieChart,
    DashboardPatientType,
  },
  data: () => ({
    dashboardMonth: [],
    dashboarPatientType: [],
    //dashboardScheduleStateYear: [],
    dashboardPieData: [],
    dashboardDoughnutData: [],
    //dashboardAppointmentPlaceYear: [],
    //optionDashboardScheduleStateYear: {},
    //optionDashboardAppointmentPlaceYear: {},
    optionDashboardbarApp: {},
    dashboardDay: [],
    listDashboardDay: [],
    loadingShow: false,
    loadingTitle: "",
    isEditing: false,
    isMobile: false,
    chartData: {},
    chartDataPieChart: {},
    chartDataDoughnutChart: {},
    DashboardPieTitle: "",
    DashboardPieSubtitle: "",
    DashboardDoughTitle: "",
    DashboardDoughSubtitle: "",
    currentTheme: localStorage.getItem("theme-color"),
  }),

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  },

  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },
    getDashboards() {
      this.loadingTitle = "Cargando indicadores...";
      this.loadingShow = true;
      apiServices.DashboardService.GetDashboards()
        .then((resp) => {
          //se obtiene informacion para dashboard mensual
          this.dashboardMonth = resp.data["dashboardMonth"];
          //se obtiene informacion para dashboard del dia
          this.dashboardDay = resp.data["dashboardDay"];
          this.listDashboardDay = this.dashboardDay.items;
          //se obtiene informacion para dashboard anual
          //this.dashboardScheduleStateYear =
          //  resp.data["dashboardScheduleStateYear"];
          //this.dashboardAppointmentPlaceYear =
          //  resp.data["dashboardAppointmentPlaceYear"];
          this.dashboardPieData = resp.data["dashboardPieData"];
          this.dashboardDoughnutData = resp.data["dashboardDoughnutData"];
          this.dashboarPatientType = resp.data["dashboardPatientType"];
          //Se arma el objeto para mostrar en el Chart de pastel, ya que es la unica forma de mostrarlo directo en el componente por medio de props

          //https://echarts.apache.org/
          /*  this.optionDashboardScheduleStateYear = {
            title: {
              text: this.dashboardScheduleStateYear.title,
              left: "left",
            },
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },

            series: [
              {
                name: this.dashboardScheduleStateYear.subtitle,
                type: "pie",
                radius: "55%",
                center: ["45%", "50%"],
                //data: this.$props.items,
                //  color: ["grey", "green", "red", "blue", "orange", "cyan"],
                color: ["red", "green", "grey"],
                //color: ["#62615F", "#478800", "#DA291C", "#0209B1", "#FE5000"],
                data: this.dashboardScheduleStateYear.items,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0)",
                  },
                },
              },
            ],
          };
          this.optionDashboardAppointmentPlaceYear = {
            title: {
              text: this.dashboardAppointmentPlaceYear.title,
              left: "left",
            },
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },

            series: [
              {
                name: this.dashboardAppointmentPlaceYear.subtitle,
                type: "pie",
                //radius: "55%",
                // center: ["45%", "50%"],
                //data: this.$props.items,
                color: ["orange", "blue", "cyan"],
                //color: ["#62615F", "#478800", "#DA291C", "#0209B1", "#FE5000"],
                data: this.dashboardAppointmentPlaceYear.items,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0)",
                  },
                },
              },
            ],
          };
          this.optionDashboardbarApp = {
            textStyle: {
              fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
            },
            dataset: {
              dimensions: ["Product", "2015", "2016", "2017"],
              source: [
                {
                  Product: "Matcha Latte",
                  2015: this.random(),
                  2016: this.random(),
                  2017: this.random(),
                },
                {
                  Product: "Milk Tea",
                  2015: this.random(),
                  2016: this.random(),
                  2017: this.random(),
                },
                {
                  Product: "Cheese Cocoa",
                  2015: this.random(),
                  2016: this.random(),
                  2017: this.random(),
                },
                {
                  Product: "Walnut Brownie",
                  2015: this.random(),
                  2016: this.random(),
                  2017: this.random(),
                },
              ],
            },
            xAxis: { type: "category" },
            yAxis: {},
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }],
          };*/
          this.chartData = {
            labels: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            datasets: [
              {
                label: "Data One",
                backgroundColor: "#f87979",
                data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
              },
              {
                label: "Data two",
                backgroundColor: "blue",
                data: [60, 40, 24, 39, 10, 40, 39, 80, 40, 20, 12, 11],
              },
            ],
          };

          this.DashboardPieTitle = this.dashboardPieData.title;
          this.DashboardPieSubtitle = this.dashboardPieData.subtitle;
          this.chartDataPieChart = {
            labels: this.dashboardPieData.labels, //["VueJs", "EmberJs", "ReactJs", "AngularJs"],
            datasets: this.dashboardPieData.datasets,
          };

          this.DashboardDoughTitle = this.dashboardDoughnutData.title;
          this.DashboardDoughSubtitle = this.dashboardDoughnutData.subtitle;
          this.chartDataDoughnutChart = {
            labels: this.dashboardDoughnutData.labels, //["VueJs", "EmberJs", "ReactJs", "AngularJs"],
            datasets: this.dashboardDoughnutData.datasets,
          };

          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    random() {
      return Math.round(300 + Math.random() * 700) / 10;
    },
  },
  mounted() {
    this.getDashboards();
    // this.getDashboardYear();
    // this.getDashboardMonth();
    // this.getDashboardDay();
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
<style scoped></style>

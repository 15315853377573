<template>
  <div id="app" v-bind:class="currentTheme">
    <div class="context-card2">
      <v-toolbar flat class="context-toolbar" color="transparent">
        <v-toolbar-title>
          <div class="context-title2">
            <h5>Historial de consultas</h5>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon right offset-y @click="closeWindow('cerrar')">
          <v-icon class="context-icon-color">close</v-icon>
        </v-btn>
      </v-toolbar>
      <SchedulesByPatient :patientSelected="selectedEvent.patient" />
    </div>
  </div>
</template>
<script>
//import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import SchedulesByPatient from "./SchedulesByPatient.vue";

export default {
  components: { SchedulesByPatient },
  data() {
    return {
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
      showHistory: false,
    };
  },
  props: {
    selectedEvent: null,
  },
  computed: {},
  mounted() {},

  methods: {
    closeWindow(value) {
      if (value == "cerrar") {
        this.$emit("closeScheduleDetail", "closeScheduleDetail");
      }
      //  this.resetForm();
    },
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
